import { Client } from '@twilio/conversations';
import { catchAndHandleError } from './utils';

class TwilioConversationManager {
  static #client;

  constructor(options) {
    this.baseUserId = `${options.userId}`;
    this.token = `${options.userToken}`;
    this.partnerLastConsumedMessageIndex = null;
    this.reconnectAttempts = 0;
  }

  static getClient(token) {
    try {
      if (!TwilioConversationManager.#client) {
        TwilioConversationManager.#client = new Client(token);
      }

      return TwilioConversationManager.#client;
    } catch (error) {
      catchAndHandleError(error, 'TwilioConversationManager', 'getClient');
    }
  }

  async connect(generateNewTwilioToken) {
    try {
      this.client = TwilioConversationManager.getClient(this.token);
      this.tokenAboutToExpire(generateNewTwilioToken);
      this.tokenExpired();
    } catch (error) {
      //we will add log and send it to Sentry later
      catchAndHandleError(error, 'TwilioConversationManager', 'connect');
    }
  }

  async joinChannel(roomCode) {
    try {
      if (!roomCode) window.location.replace('/matchprofile');

      this.conversationClient = await this.client.getConversationByUniqueName(
        roomCode
      );

      await this._getPartnerLastConsumedMessageIndex();
      this.userClient = await this.client.getUser(this.baseUserId);
      let members = await this.conversationClient.getParticipants();
      let currentUser = members.find(
        member => member.identity === this.baseUserId
      );
      if (currentUser) {
        await currentUser.updateAttributes({ cancelDating: false });
      }
    } catch (error) {
      //we will add log and send it to Sentry later
      catchAndHandleError(error, 'TwilioConversationManager', 'joinChannel');
    }
  }

  subscribeToRoom(hooks) {
    try {
      let { messageAdded, userUpdated, memberUpdated } = hooks;
      this.conversationClient.on('participantUpdated', message =>
        memberUpdated(message)
      );
      this.conversationClient.on('messageAdded', message =>
        messageAdded(message)
      );
      this.userClient.on('updated', message => userUpdated(message));
    } catch (error) {
      catchAndHandleError(
        error,
        'TwilioConversationManager',
        'subscribeToRoom'
      );
    }
  }

  sendMessage = async message => {
    try {
      if (message.trim().length) {
        if (!(await this.isCancelDating())) {
          return this.conversationClient.sendMessage(message);
        } else {
          //we will add log and send it to Sentry later
          window.location.replace('/matchprofile');
        }
      }
    } catch (error) {
      //we will add log and send it to Sentry later
      catchAndHandleError(error, 'TwilioConversationManager', 'sendMessage');
    }
  };

  sendImage = async (formData, callback) => {
    try {
      if (!(await this.isCancelDating())) {
        this.conversationClient.sendMessage(formData).then(() => {
          callback();
        });
      } else {
        //we will add log and send it to Sentry later
        window.location.replace('/matchprofile');
      }
    } catch (error) {
      //we will add log and send it to Sentry later
      catchAndHandleError(error, 'TwilioConversationManager', 'sendImage');
    }
  };

  _getPartnerLastConsumedMessageIndex = async () => {
    try {
      let members = await this.conversationClient.getParticipants();
      if (this.baseUserId && members.length) {
        let partner = members.find(
          member => member.identity !== this.baseUserId
        );
        if (partner) {
          this.partnerLastConsumedMessageIndex = partner.lastReadMessageIndex;
        }
      }
    } catch (error) {
      //we will add log and send it to Sentry later
      catchAndHandleError(
        error,
        'TwilioConversationManager',
        '_getPartnerLastConsumedMessageIndex'
      );
    }
  };

  async getMessages(messageLimit = 100) {
    try {
      let allMessages = [];
      let hasPrevPage = false;
      let messageAnchor = 999999;
      do {
        let resp = await this.conversationClient.getMessages(
          messageLimit,
          messageAnchor
        );
        let messages = resp ? resp.items : [];
        if (messages.length) {
          !!hasPrevPage && messages.pop();
          allMessages = messages.concat(allMessages);
        }
        hasPrevPage = resp.hasPrevPage;
        messageAnchor = (allMessages[0] && allMessages[0].index) || 0;
      } while (hasPrevPage);
      return allMessages;
    } catch (error) {
      //we will add log and send it to Sentry later
      catchAndHandleError(error, 'TwilioConversationManager', 'getMessages');
    }
  }

  async setReadCursor(position) {
    try {
      await this.conversationClient.updateLastReadMessageIndex(position);
      return true;
    } catch (error) {
      catchAndHandleError(error, 'TwilioConversationManager', 'setReadCursor');
      return false;
    }
  }

  async cancelDating() {
    try {
      let members = await this.conversationClient.getParticipants();
      let currentUser = members.find(
        member => member.identity === this.baseUserId
      );
      if (currentUser) {
        await currentUser.updateAttributes({ cancelDating: true });
      }
    } catch (error) {
      //we will add log and send it to Sentry later
      catchAndHandleError(error, 'TwilioConversationManager', 'cancelDating');
    }
  }

  async isCancelDating() {
    try {
      let members = await this.conversationClient.getParticipants();
      let partner = members.find(member => member.identity !== this.baseUserId);
      return partner.attributes.cancelDating;
    } catch (error) {
      //we will add log and send it to Sentry later
      catchAndHandleError(error, 'TwilioConversationManager', 'isCancelDating');
    }
  }

  tokenAboutToExpire(generateNewTwilioToken) {
    this.client.on('tokenAboutToExpire', async () => {
      const { token, identity } = await generateNewTwilioToken();
      try {
        await this.client.updateToken(token);
        this.baseUserId = identity;
      } catch (error) {
        //we will add log and send it to Sentry later
        catchAndHandleError(
          error,
          'TwilioConversationManager',
          'tokenAboutToExpire'
        );
      }
    });
  }

  tokenExpired = () => {
    this.client.on('tokenExpired', async () => {
      try {
        window.location.replace('/matchprofile');
      } catch (error) {
        //we will add log and send it to Sentry later
        catchAndHandleError(error, 'TwilioConversationManager', 'tokenExpired');
      }
    });
  };
}

export default TwilioConversationManager;
