import { ChatFeature } from '@constants/constant';
import { getPartnerProfileImage } from './helpers';
import { store } from '@redux/store';
import { requestSendExceptionNotice } from '@redux/actions';

export const checkUserType = (author, currentUserId) => {
  return Number(author) === Number(currentUserId)
    ? ChatFeature.USER_TYPE_BASE_USER
    : ChatFeature.USER_TYPE_PARTNER_USER;
};

export const renderAvatar = (author, currentUserId, partnerData) => {
  return Number(author) === Number(currentUserId)
    ? null
    : getPartnerProfileImage(partnerData);
};

export const catchAndHandleError = (error, componentName, functionName) => {
  let errorData = {
    message:
      error.message + ` at ${componentName} and function ${functionName}`,
    stack: error.stack
  };
  store.dispatch(requestSendExceptionNotice({ error: errorData }));
};
