import React, { useState, useRef, useCallback } from 'react';
import NavBarMain from '../NavBar';
import styles from './index.module.css';
import ChatRoom from './ChatRoom';
import ChatTextInput from './ChatTextInput';
import { renderLeftContentChat } from '@utils';
import classNames from 'classnames';
import { ReactComponent as ArrowRightIcon } from '@assets/ChatFeature/ArrowRight.svg';
import { ReactComponent as UnionIcon } from '@assets/ChatFeature/union.svg';
import moment from 'moment';
import FirstTimeSeeNewDesign from './FirstTimeSeeNewDesign';
import GroupActionButton from './GroupActionButton';
import { ReactComponent as XIcon } from '@assets/ChatFeature/x-icon.svg';
import PropTypes from 'prop-types';
import _ from 'lodash';

const ChatFeature = ({
  chatRoomIsReady,
  partnerProfileImage,
  partnerData,
  chatRoomMessages,
  bachelorChatManager,
  transferTicketPopup,
  fetchMoreMessages,
  shouldScrollToLatestMessage,
  textAgeS,
  saveChatHistory,
  onUserAvatarClick,
  onBackButtonClick,
  isWhiteMode,
  dateTimeText,
  dataTimeSlot,
  handelClickGoToDetailPartner,
  matchingDateData,
  isShowUnreadMessage,
  isShowPopupNewChatDesign,
  requestChangeShowNewChatDesign,
  onCafeSuggestionLinkClick,
  onVideoSuggestionLinkClick,
  onChangeTimeClick,
  openCancelDatingPopup,
  onPopupNewChatDesignClick,
  hasWhiteHeader,
  handleGenerateNewTwilioToken
}) => {
  const { job, age } = partnerData || {};
  let cafeInfo = {};
  if (chatRoomMessages.length > 0) {
    cafeInfo = chatRoomMessages[0].data;
  }
  const dtLength = dateTimeText.length;
  const datingTime = dateTimeText.substring(dtLength - 9, dtLength - 1);
  let newDatingTime = moment(datingTime, 'HH:mm:ss', true).isValid()
    ? moment(datingTime, 'HH:mm').format('HH:mm')
    : null;
  if (newDatingTime) {
    dateTimeText = dateTimeText.replace(datingTime, newDatingTime);
  }
  const [isTyping, setIsTyping] = useState(false);
  const [openGroupBtn, setOpenGroupBtn] = useState(false);
  const chatInputWrapperRef = useRef(null);
  const textAreaRef = useRef(null);
  const btnAreaRef = useRef(null);
  // Create a ref for storing the most recent clicked element
  const historyClickRef = useRef(null);

  const [rowOfTextarea, setRowOfTextarea] = useState(1);

  // Define a function to handle all clicks on the document
  const handleClick = useCallback(event => {
    // Store the clicked element in the ref, unless it was the Send button
    if (
      btnAreaRef &&
      btnAreaRef.current &&
      !btnAreaRef.current.contains(event.target)
    )
      historyClickRef.current = event.target;
  }, []);

  const [fixPosition, setFixPosition] = React.useState(0);
  const toolbarWrapRef = useRef(null);
  const toolbarRef = useRef(null);

  React.useEffect(() => {
    const setMargin = () => {
      const newPosition =
        toolbarWrapRef &&
        toolbarWrapRef.current &&
        toolbarWrapRef.current.getBoundingClientRect() &&
        toolbarWrapRef.current.getBoundingClientRect().top;
      if (newPosition < -1) {
        toolbarRef.current.classList.add('down');
        let newFixPosition = Math.abs(newPosition);
        if (
          window.innerHeight + window.pageYOffset >=
          document.body.offsetHeight
        ) {
          newFixPosition -= 2;
        }
        setFixPosition(newFixPosition);
        toolbarRef.current.style.marginTop = newFixPosition + 'px';
      }
    };

    const debounceMargin = _.debounce(setMargin, 150);

    const showToolbar = () => {
      if (fixPosition > 0) {
        toolbarRef.current.classList.remove('down');
        setFixPosition(0);
        toolbarRef.current.style.marginTop = '0px';
      }
      debounceMargin();
    };

    window.addEventListener('scroll', showToolbar);
    textAreaRef &&
      textAreaRef.current &&
      textAreaRef.current._ref &&
      textAreaRef.current._ref.addEventListener('blur', showToolbar);

    return () => {
      window.removeEventListener('scroll', showToolbar);
      textAreaRef &&
        textAreaRef.current &&
        textAreaRef.current._ref &&
        textAreaRef.current._ref.removeEventListener('blur', showToolbar);
    };
  }, [fixPosition]);

  // Attach the handleClick function to the document click event
  React.useEffect(() => {
    document.addEventListener('click', handleClick);

    // Remove the handleClick function from the document click event on unmount
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  const chatHeader = (
    <>
      <div
        className={styles.boxHeaderContainer}
        onClick={() => handelClickGoToDetailPartner()}
      >
        <div className={styles.boxHeaderContent}>
          <img
            alt=""
            src={partnerProfileImage ? partnerProfileImage : ''}
            className={classNames({
              [styles.navIconImgChat]: true
            })}
          />
          <div className={styles.boxContent}>
            <div className={styles.headerContent}>
              {age}歳, {job ? job.displayName : null}
            </div>
            <div className={styles.displayFlex}>
              <div className={styles.headerContent}>
                待ち合わせ: {cafeInfo ? cafeInfo.cafeName : null}
              </div>
              <div className={styles.displayFlex}>
                {'('}
                <div
                  className={classNames([
                    styles.headerContent,
                    styles.stationName
                  ])}
                >
                  {cafeInfo ? cafeInfo.stationName : null}
                </div>
                {')'}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.boxArrowRight}>
          <ArrowRightIcon />
        </div>
      </div>
    </>
  );

  const handleCloseGroupButton = () => {
    setOpenGroupBtn(false);
    setIsTyping(true);
    textAreaRef.current._ref.focus();
  };

  const handleOpenGroupButton = () => {
    setTimeout(() => {
      setOpenGroupBtn(true);
      setIsTyping(true);
    }, 500);
  };

  return (
    <>
      <div id="toolbar-wrap" ref={toolbarWrapRef}>
        <div id="toolbar" ref={toolbarRef}>
          <NavBarMain
            customClassName={styles.navBar}
            isWhiteMode={isWhiteMode}
            title={job && `${age}${textAgeS}${job}`}
            userAvatar={partnerProfileImage ? partnerProfileImage : ''}
            shouldBlurIcon={false}
            onUserAvatarClick={onUserAvatarClick}
            dateTimeText={dateTimeText}
            dataTimeSlot={dataTimeSlot}
            leftContent={[
              renderLeftContentChat(
                true,
                isWhiteMode,
                onBackButtonClick,
                matchingDateData.unread_count,
                isShowUnreadMessage,
                hasWhiteHeader
              )
            ]}
            chatHeader={chatHeader}
          />
        </div>
      </div>

      <div
        className={classNames(styles.wrapper, {
          [styles.wrapperWhiteMode]: isWhiteMode
        })}
      >
        <ChatRoom
          chatRoomIsReady={chatRoomIsReady}
          messages={chatRoomMessages}
          bachelorChatManager={bachelorChatManager}
          fetchMoreMessages={fetchMoreMessages}
          shouldScrollToLatestMessage={shouldScrollToLatestMessage}
          partnerData={partnerData}
          openGroupBtn={openGroupBtn}
          handelClickGoToDetailPartner={handelClickGoToDetailPartner}
          isTyping={isTyping}
        />
      </div>

      <div className={styles.chatInputGroup}>
        <div
          ref={chatInputWrapperRef}
          className={classNames(styles.ChatTextInputWrapper, {
            [styles.chatTextInputFlexEnd]: true
          })}
        >
          <div className={styles.NewChatGroup}>
            <div className={styles.PopupNewChatDesignWrapper}>
              {openGroupBtn ? (
                <XIcon onClick={handleCloseGroupButton} />
              ) : (
                <UnionIcon onClick={handleOpenGroupButton} />
              )}
              <FirstTimeSeeNewDesign
                isShowPopupNewChatDesign={isShowPopupNewChatDesign}
                requestChangeShowNewChatDesign={requestChangeShowNewChatDesign}
                onPopupNewChatDesignClick={onPopupNewChatDesignClick}
              />
            </div>
          </div>
          <ChatTextInput
            isWhiteMode={isWhiteMode}
            chatRoomIsReady={chatRoomIsReady}
            bachelorChatManager={bachelorChatManager}
            saveChatHistory={saveChatHistory}
            isTyping={isTyping}
            setIsTyping={setIsTyping}
            setOpenGroupBtn={setOpenGroupBtn}
            rowOfTextarea={rowOfTextarea}
            setRowOfTextarea={setRowOfTextarea}
            textAreaRef={textAreaRef}
            btnAreaRef={btnAreaRef}
            historyClickRef={historyClickRef}
            handleGenerateNewTwilioToken={handleGenerateNewTwilioToken}
          />
        </div>
        {openGroupBtn && (
          <GroupActionButton
            setOpenGroupBtn={setOpenGroupBtn}
            onCafeSuggestionLinkClick={onCafeSuggestionLinkClick}
            onVideoSuggestionLinkClick={onVideoSuggestionLinkClick}
            onChangeTimeClick={onChangeTimeClick}
            transferTicketPopup={transferTicketPopup}
            openCancelDatingPopup={openCancelDatingPopup}
          />
        )}
      </div>
    </>
  );
};

ChatFeature.propTypes = {
  isWhiteMode: PropTypes.bool,
  chatRoomIsReady: PropTypes.bool,
  partnerProfileImage: PropTypes.string,
  fetchMoreMessages: PropTypes.func,
  partnerData: PropTypes.object,
  chatRoomMessages: PropTypes.array,
  bachelorChatManager: PropTypes.any,
  transferTicketPopup: PropTypes.func,
  textAgeS: PropTypes.string,
  shouldScrollToLatestMessage: PropTypes.bool,
  saveChatHistory: PropTypes.func,
  onUserAvatarClick: PropTypes.func,
  onBackButtonClick: PropTypes.func,
  dateTimeText: PropTypes.string,
  handelClickGoToDetailPartner: PropTypes.func,
  matchingDateData: PropTypes.object,
  isShowUnreadMessage: PropTypes.bool,
  isShowPopupNewChatDesign: PropTypes.bool,
  requestChangeShowNewChatDesign: PropTypes.func,
  onCafeSuggestionLinkClick: PropTypes.func,
  onVideoSuggestionLinkClick: PropTypes.func,
  onChangeTimeClick: PropTypes.func,
  openCancelDatingPopup: PropTypes.func,
  onPopupNewChatDesignClick: PropTypes.func,
  handleGenerateNewTwilioToken: PropTypes.func
};

export default ChatFeature;
